import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import ShinyButton from '../../components/ShinyButton';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';
import { useForm } from 'react-hook-form';

import './style.scss';

const SeniorGraphicDesignerPage = () => {
    const { getValues, setValue } = useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFromlocalStorage, setdataFromlocalStorage] = useState();
    const [resumeFileName, setResumeFileName] = useState('');

    useEffect(() => {
        retrieveDataFromLocalStorage();
    }, [currentPage]);

    const retrieveDataFromLocalStorage = () => {
        if (localStorage.getItem('resumeData') !== null) {
            const savedValues = JSON.parse(localStorage.getItem('resumeData'));
            setdataFromlocalStorage(savedValues);
            for (let key in savedValues) {
                setValue(key, savedValues[key]);
            }
        }
    };

    const checkUploadedFileSize = file => {
        if (file.size > 52428800) {
            alert('File is too big! Max resume size is 50MB.');
            file.value = '';
            return false;
        }
        return true;
    };

    const setUploadedFileName = (resume, file) => {
        if (checkUploadedFileSize(file[0])) {
            const selectedFile = file[0].name.split('\\');
            localStorage.setItem('resumeData', JSON.stringify(getValues()));

            if (resume === 'resume') {
                setValue('resume', file);
                setResumeFileName(
                    'File Uploaded: ' + selectedFile[selectedFile.length - 1]
                );
            }
        }
    };

    const uploadResume = () => {
        const resumeUpload = document.getElementById('resume');
        resumeUpload.click();
    };

    return (
        <Layout>
            <Seo title="Senior Graphic Designer" />
            <div className="senior-graphic-designer-page">
                <Container className="purple-gradient-top container-1 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h1>Senior Graphic Designer</h1>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-2 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h3>Who we’re looking for</h3>
                            <p className=" wrapLineSib">
                                We're looking to hire a Senior Graphic Designer
                                to join our team at GlassHive, a leading work OS
                                in the IT channel. As a Senior Graphic Designer,
                                you will be expected to lead projects from
                                concept to completion, all while ensuring that
                                our brand is consistent and the end product is
                                of high quality.
                            </p>
                            <p>
                                We’re looking for an idea-driven,
                                self-sufficient, conceptual thinker who designs
                                creative solutions for brand identity, web, and
                                collateral. The ideal candidate is creative and
                                can bring B2B business strategy/concepts alive
                                in their design.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-3 purple-gradient-bottom">
                    <Row>
                        <Col className=" transparent-box max-width-900 margin-auto">
                            <h3>Responsibilities</h3>
                            <li className=" wrapLineSib">
                                Create and assemble images and graphics to
                                produce designs for websites, email, print
                                media, product design, displays, and productions
                            </li>
                            <li>
                                Work with the internal team to ensure all
                                material meets standards and vision
                            </li>
                            <li>
                                Collaborate with multiple departments,
                                especially the Multimedia Department
                            </li>
                            <li>
                                Design and communicate GlassHive's brand
                                identity
                            </li>
                            <li>
                                Articulate your design concepts to the CMO with
                                vigor and enthusiasm
                            </li>
                            <li>
                                Create templates, drafts, and design specs for
                                the GlassHive
                            </li>
                            <li>
                                Assist the CMO and other Department Leads in
                                cooking up creative and innovative marketing
                                solutions
                            </li>
                            <li>
                                Mentor and assist in training other designers
                            </li>
                            <li>
                                Assist with the review and evaluation of
                                personnel performance when needed
                            </li>
                            <li>
                                Meet with channel vendors and collaborate with
                                their internal marketing departments
                            </li>
                            <li>
                                Conceptualize and execute the creative design
                                strategy behind ad campaigns
                            </li>
                            <li>Design and produce a wide array of media</li>
                            <li>
                                Develop our company brand to increase brand
                                presence
                            </li>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="transparent-box-2 max-width-900 margin-auto">
                            <h3>Required Skills</h3>
                            <li className=" wrapLineSib">
                                8+ years experience in graphic design
                            </li>
                            <li>
                                Knowledge of design principles, including
                                layout, typography, color balance, composition,
                                and current design trends – demonstration of
                                these skills through the delivery of a project
                                portfolio of work, preferably delivered in a
                                digital format
                            </li>
                            <li>
                                High-level knowledge of design, web, and print
                            </li>
                            <li>
                                Demonstrate a high level of proficiency in
                                Designing Software such as Photoshop,
                                Illustrator, InDesign, and Microsoft and Apple
                                products
                            </li>
                            <li>
                                Experience in designing marketing materials for
                                B2B (business-to-business)
                            </li>
                            <li>
                                Ability to function as a contributing team
                                member and interact positively and openly with
                                peers and other departments
                            </li>
                            <li>
                                Ability to recognize and attend to important
                                details with accuracy and efficiency
                            </li>
                            <li>
                                Demonstrate strong problem-solving,
                                communication, organizational, and multi-tasking
                                skills
                            </li>
                            <li>
                                Aptitude to work constructively under stress in
                                a fast-paced environment
                            </li>
                            <li>
                                Ability to learn, take direction, and handle
                                critiques
                            </li>
                            <li>
                                Ability to work independently and in a team
                                environment
                            </li>
                            <li>
                                Being able to accept criticism gracefully and
                                clearly and concisely communicate ideas when
                                necessary
                            </li>
                            <li>
                                Ability to lead projects with a creative team
                            </li>
                            <li>Good written and oral communication skills</li>
                            <li>Great customer service skills</li>
                            <li>Time Management Skills</li>
                            <li>A CAN-DO ATTITUDE</li>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-4  ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <AppForm
                                formName="careers-form"
                                submitBtnText="Send"
                                className="pink-form careers-form"
                                formCategory="Careers"
                            >
                                <h3 class="centered-text">
                                    Apply now for Senior Graphic Designer
                                </h3>
                                <AppInput
                                    fieldName="firstName"
                                    type="text"
                                    labelText="What's your name?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    labelText="What's your email address?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="phone"
                                    type="text"
                                    labelText="What's your phone number?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="why"
                                    type="text"
                                    labelText="Why do you want to work for GlassHive?"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="goal"
                                    type="text"
                                    labelText="What do you want to achieve with a career at GlassHive?"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="passion"
                                    type="text"
                                    labelText="What are you passionate about? (Doesn't have to be business related)"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="movie"
                                    type="text"
                                    labelText="What's your favorite movie?"
                                />
                                <div className="file centered-text">
                                    <label
                                        htmlFor="resume"
                                        className="custom-file-upload"
                                    >
                                        <input
                                            type="file"
                                            name="resume"
                                            id="resume"
                                            className="file-upload"
                                            required
                                            onChange={e => {
                                                setUploadedFileName(
                                                    'resume',
                                                    e.target.files
                                                );
                                            }}
                                        />
                                        <p className="choose-a-file">
                                            Drop video resume/cover letter here
                                            or
                                            <ShinyButton
                                                className="btn white create-free-account-button"
                                                onClick={() => uploadResume()}
                                            >
                                                Select Files
                                            </ShinyButton>
                                        </p>
                                        <p id="fileUploadedName">
                                            {resumeFileName}
                                        </p>
                                    </label>
                                </div>
                                <AppInput
                                    fieldName="video"
                                    type="text"
                                    labelText="Video or Portfolio URL"
                                />
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default SeniorGraphicDesignerPage;
